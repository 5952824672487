.AdminModalContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.AdminModal {
    width: 33vw;
    height: 50vh;
    border: 2px solid #333;
    border-radius: .5rem;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(20, 1fr);
    grid-template-rows: repeat(10, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}
.AdminModalTitle {
    grid-area: 1 / 1 / 2 / 21;
    background: rgb(76,106,145);
    background: linear-gradient(180deg, rgba(76,106,145,1) 0%, rgba(60,84,116,1) 100%);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3vh;
}
.AdminModalBody {
    grid-area: 2 / 1 / 10 / 21;
    background: #eee;
    color: #333;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    font-size: 2vh;
    border-top: 1px solid #333;
    border-bottom: 1px solid #333;
}
.AdminModalBodyRow {
    text-align: center;
    padding: 1.5vh;
    padding-bottom: 0vh;
}
.AdminModalButtonRow {
    grid-area: 10 / 1 / 11 / 21;
    background: rgb(204,204,204);
    background: linear-gradient(180deg, rgba(204,204,204,1) 0%, rgba(136,136,136,1) 100%);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.AdminModalButton {
    width: 7.5vw;
    height: 80%;
}
.AdminInput {
    width: 100%;
}
