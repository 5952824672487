
.InputTitle {
    grid-area: 2 / 2 / 4 / 21;
    
}

.InputForm {
    grid-area: 4 / 2 / 19 / 21;
    background: #eeeeee;
    border: 1px solid #666666;
    display: grid;
    grid-template-columns: repeat(19, 1fr);
    grid-template-rows: repeat(15, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}

.InputControls {
    grid-area: 19 / 2 / 21 / 21;
    background: rgb(204,204,204);
    background: linear-gradient(180deg, rgba(204,204,204,1) 0%, rgba(136,136,136,1) 100%);
    border-bottom-left-radius: .5rem;
    border-bottom-right-radius: .5rem;
    border-left: 1px solid #666666;
    border-right: 1px solid #666666;
    border-bottom: 1px solid #666666;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.FirstURLContainer {
    grid-area: 2 / 2 / 4 / 14;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
}
.InputFirstURL {
    width: 88%;
    height: 6vh;
}
.ButtonClearFirstURL {
    width: 12%;
    height: 6vh; 
}

.LastURLContainer {
    grid-area: 5 / 2 / 7 / 14;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
}
.InputLastURL {
    width: 88%;
    height: 6vh;
}
.ButtonClearLastURL {
    width: 12%;
    height: 6vh;
}

.ButtonAddToCase {
    grid-area: 3 / 15 / 6 / 19;
    place-self: center;
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.ListCurrentCase {
    grid-area: 8 / 2 / 15 / 19;
    overflow-x: hidden;
    overflow-y: auto;
    background: white;
    border: 1px solid #4C6A91;
    border-radius: .5rem;
}

.ButtonFinishCase {
    grid-area: 19 / 9 / 21 / 14;
    width: 7.5vw;
    height: 75%;
}

table#CurrentCase tr {
    height: 4vh;
}


