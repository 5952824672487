.LoginLogo {
    grid-area: 2 / 2 / 6 / 12;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.LoginLogo img {
    object-fit: contain;
    max-width: 80%;
    height: auto;
}

.LoginInputUserContainer {
    grid-area: 7 / 2 / 9 / 12;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.LoginInputUser {
    width: 80%;
}
.LoginError {
    grid-area: 9 / 2 / 10 / 12;
    color: red;
    place-self: center;
}
.LoginInputPasswordContainer {
    grid-area: 10 / 2 / 12 / 12;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.LoginInputPassword {
    width: 80%;
}

.LoginButtonSubmit {
    grid-area: 13 / 5 / 15 / 9;
    font-size: 1.25rem;
    height: 75%;
    width: 100%;
    place-self: center;
}