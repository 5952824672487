
.LabelTitle {
    grid-area: 2 / 2 / 4 / 21;
}

.LabelPreviewContainer {
    grid-area: 4 / 2 / 19 / 21;
    background: #eeeeee;
    border: 1px solid #666666;
    /* Edit: change to flex for labelary img
    display: grid;
    grid-template-columns: repeat(18, 1fr);
    grid-template-rows: repeat(14, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    */
    display: flex;
}

.LabelImageContainer {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.LabelImage {
    max-height: 96%;
    max-width: 96%;
    object-fit: contain;
}
img.LabelImage {
    border: 1px solid #666;
    box-shadow: 2px 2px 5px 0px #666;
}

.LabelQRCode {
    grid-area: 1 / 1 / 9 / 9;
    display: flex;
    justify-content: center;
    align-items: center;
}
.LabelHeader {
    grid-area: 1 / 9 / 3 / 17;
    background: black;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    
}
.LabelControls {
    grid-area: 19 / 2 / 21 / 21;
    background: rgb(204,204,204);
    background: linear-gradient(180deg, rgba(204,204,204,1) 0%, rgba(136,136,136,1) 100%);
    border-bottom-left-radius: .5rem;
    border-bottom-right-radius: .5rem;
    border-left: 1px solid #666666;
    border-right: 1px solid #666666;
    border-bottom: 1px solid #666666;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.ButtonPrint {
    grid-area: 19 / 13 / 21 / 18;
    height: 75%;
    width: 8vw;
    font-size: 2vh;
}

.LabelSize {
    grid-area: 19 / 5 / 21 / 10;
    height: 75%;
    width: 8vw;
}

.select-css {
    text-align-last: center;
	display: block;
	font-size: 2vh;
	font-family: sans-serif;
	font-weight: 700;
	color: #444;
	line-height: 1.3;
	padding: .6em 1.4em .5em .8em;
	width: 100%;
    max-width: 100%;
    height: 100%;
	box-sizing: border-box;
	margin: 0;
	border: 1px solid #4C6A91;
	border-radius: .5em;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: #fff;
	background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
	  linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
	background-repeat: no-repeat, repeat;
	background-position: right .7em top 50%, 0 0;
	background-size: .65em auto, 100%;
}
.select-css::-ms-expand {
	display: none;
}
.select-css:hover {
	border-color: #333333;
}
.select-css:focus {
	outline: 0;
    border-color: #7f00ff;
    box-shadow: 0 0 5px 2px #7f00ff;
}
.select-css:disabled {
    color: gray;
    opacity: .5; 
}
.select-css option {
	font-weight:normal;
}
