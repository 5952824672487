.UpdateModalContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.UpdateModal {
    width: 25vw;
    height: 33vh;
    border: 2px solid #333;
    border-radius: .5rem;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(20, 1fr);
    grid-template-rows: repeat(10, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}
.UpdateModalTitle {
    grid-area: 1 / 1 / 3 / 21;
    background: rgb(76,106,145);
    background: linear-gradient(180deg, rgba(76,106,145,1) 0%, rgba(60,84,116,1) 100%);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3vh;
}
.UpdateModalBody {
    grid-area: 3 / 1 / 9 / 21;
    background: #eee;
    color: #333;
    display: flex;
    flex-direction: column;
    font-size: 2vh;
    border-top: 1px solid #333;
    border-bottom: 1px solid #333;
}
.UpdateModalBodyRow {
    text-align: center;
    padding: 1.5vh;
    padding-bottom: 0vh;
}
.UpdateModalButtonRow {
    grid-area: 9 / 1 / 11 / 21;
    background: rgb(204,204,204);
    background: linear-gradient(180deg, rgba(204,204,204,1) 0%, rgba(136,136,136,1) 100%);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.UpdateModalButton {
    width: 7.5vw;
    height: 60%;
}
