.WarningContainer {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    font-size: 5vh;
    justify-content: space-evenly;
    align-items: center;
    background: rgb(76,106,145);
    background: linear-gradient(180deg, rgba(76,106,145,1) 0%, rgba(60,84,116,1) 100%);
    color: white;
}

.WarningText {
    text-align: center;
    max-width: 60vw;
    max-height: 60vh;
}

.WarningButton {
    height: 10vmin;
    width: 25vmin;
}