.NavContainer {
    grid-area: 1 / 1 / 3 / 49;
    background: #4C6A92;
    display: grid;
    grid-template-columns: repeat(48, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    box-shadow: 0px 3px 5px 0px gray;
    z-index: 1;
}

.NavLeft {
    grid-area: 1 / 1 / 3 / 25;
    
    color: white;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.NavRight {
    grid-area: 1 / 25 / 3 / 49;
    font-size: 2.5vh;
    
    color: white;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-start;
}

.NavRightLink {
    margin-right: 5vmin;
    display: flex;
    align-items: center;
    justify-content: center;
}
.NavRightLink img{
    margin-right: .5vw;
    height: 3vmin;
    filter: invert(100%) sepia(59%) saturate(2%) hue-rotate(77deg) brightness(113%) contrast(100%);
}
.SyncLink {
    width: 15vw;
}
.NavRightLink a {
    cursor: pointer;
}

.NavLeftLogo {
    margin-left: 2.5vw;
    font-size: 5vh;
    font-family: 'Quicksand Bold';
    display: flex;
    align-items: center;
    justify-content: space-between;    
}
.NavLeftLogo svg {
    margin-right: 1vw;
}

.Status {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: .5rem;
    padding: .25rem;
    background: #eee;
    border: 2px solid lightblue;
    font-size: 2vmin;
    width: 15vw;
}
.StatusLabel {
    font-family: 'Quicksand Medium';
    color: #333;
    margin-right: .5vw;
}
.StatusSynced {
    color: green;
}
.StatusSyncedText {
    font-family: 'Quicksand Medium';
    color: green;
    margin-right: .5vw;
}
.StatusSyncing {
    color: blue;
}
.StatusSyncingText {
    font-family: 'Quicksand Medium';
    color: blue;
    margin-right: .5vw;
}
.StatusOffline {
    color: red;
}
.StatusOfflineText {
    font-family: 'Quicksand Medium';
    color: red;
    margin-right: .5vw;
}
