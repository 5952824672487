.HelpContainer {
    grid-area: 1 / 1 / 28 / 49;
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(48, 1fr);
    grid-template-rows: repeat(25, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    overflow: hidden;
    z-index: 3;
    background-color: rgba(0,0,0,.5)
}

.HelpWindow {
    grid-area: 2 / 11 / 24 / 39;
    display: grid;
    grid-template-columns: repeat(28, 1fr);
    grid-template-rows: repeat(21, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}

.HelpTitle {
    grid-area: 1 / 1 / 3 / 29;
    border: 1px solid #888;
    border-bottom: 0px;
}

.HelpMenu {
    grid-area: 3 / 1 / 4 / 29;
    background-color: white;
}
.HelpTab {
    height: 100%;
    background-color: gray;
    color: white;
    border: 1px solid black;
}
.HelpTabActive {
    height: 100%;
    background-color: white;
    color: black;
    border: 1px solid black;
    border-bottom: 0px;
}

.HelpBody {
    grid-area: 4 / 1 / 20 / 29;
    background-color: white;
    border: 1px solid #666666;
    border-top: 0px;
}

.HelpControls {
    grid-area: 20 / 1 / 22 / 29;
    background: rgb(204,204,204);
    background: linear-gradient(180deg, rgba(204,204,204,1) 0%, rgba(136,136,136,1) 100%);
    border-bottom-left-radius: .5rem;
    border-bottom-right-radius: .5rem;
    border-left: 1px solid #666666;
    border-right: 1px solid #666666;
    border-bottom: 1px solid #666666;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.HelpCloseWindow {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-area: 1 / 27 / 3 / 29;
    z-index: 2;
    background: none;
    outline: 0;
    border: 0;
}

.SVGCloseWindow {
    height: 5vmin;
    width: 5vmin;
    border-radius: 50%;
    background-color: transparent;
    transition: background-color .5s;   
}
.SVGCloseWindow:hover {
    background-color: gray;
}
.SVGCloseWindow:focus {
    outline: 0;
    border-color: #7f00ff;
    border-radius: 50%;
    box-shadow: 0 0 5px 2px #7f00ff;   
}