:root {
    --main-color: #4C6A91;
    --main-color-dark: #274a78;
    --secondary-color: #91734c;
    --highlight: #7f00ff;
}

div {
    font-family: 'Quicksand';
}

button {
    font-family: 'Quicksand Bold';
}

input {
    font-family: 'Quicksand';
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}

.FlexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Title {
    color: white;
    background: rgb(76,106,145);
    background: linear-gradient(180deg, rgba(76,106,145,1) 0%, rgba(60,84,116,1) 100%);
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 5vh;
    font-family: 'Quicksand Medium'
}

.TitleLong {
    color: white;
    background: rgb(76,106,145);
    background: linear-gradient(180deg, rgba(76,106,145,1) 0%, rgba(60,84,116,1) 100%);
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3.5vh;
    font-family: 'Quicksand Medium'
}

.Button {
    font-size: 2vh;
    opacity: 1;
    border-radius: .5rem;
    border: .5px solid #888888;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background: rgb(76,106,145);
    background-image: linear-gradient(90deg, rgba(46,76,115,1) 0%, rgba(106,136,175,1) 100%);
    background-size: 300% 300%;
    background-position: left;
    transition: background-position .5s, color 1s;
}
.Button:hover {
    background-position: right;
    color: #f9d77e;
}
.Button:focus {
    outline: 0;
    border-color: #7f00ff;
    box-shadow: 0 0 5px 2px #7f00ff;
    
}

.Button:disabled {
    color: gray;
    opacity: .75;
    pointer-events: none;
}

.ButtonAppend {
    opacity: 1;
    color: white;
    border-top-right-radius: .5rem;
    border-bottom-right-radius: .5rem;
    /* border: 2px solid #888888; */
    border-left: 0px;
    background: rgb(76,106,145);
    background-image: linear-gradient(90deg, rgba(46,76,115,1) 0%, rgba(106,136,175,1) 100%);
    background-size: 300% 300%;
    background-position: left;
    transition: background-position .5s, color 1s;
}
.ButtonAppend:hover {
    background-position: right;
    color: #f9d77e;
}
.ButtonAppend:focus {
    outline: 0;
    border-top-color: #7f00ff;
    border-right-color: #7f00ff;
    border-bottom-color: #7f00ff;
    box-shadow: 0 0 5px 2px #7f00ff; 
}
.ButtonAppend:disabled {
    opacity: .75;
    pointer-events: none;
}

.Shadow {
    box-shadow: 0px 1px 3px 0px black;
}

.Input {
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem;
    border: 2px solid #888888;
    border-right: 0px;
    padding-left: 1vw;
    font-size: 2.5vh;
    color: #333333;
    transition: background-color .5s, color .5s;
}
.Input:focus {
    outline: 0;
    border: 2px solid #4C6A91;
    border-right: 0px;
    background-color: #ADD8E6;
}
.Input:focus::placeholder {
    color: black;
}

.InputNoAppend {
    border-radius: .5rem;
    border: 1.5px solid #888888;
    padding: 1vmin;
    padding-left: 2vmin;
    font-size: 2.5vmin;
    color: #333333;
}
.InputNoAppend:hover {
    outline: 0;
    border: 1.5px solid rgb(115, 144, 182);
}
.InputNoAppend:focus {
    outline: 0;
    border: 1.5px solid #4C6A91;
}
/* Table styling */
td, table {
    border-bottom: 1px solid gray;
    border-left: 1px solid gray;
    border-right: 1px solid gray;
    border-collapse: collapse;
    
  }
  td {
    padding: 0;
    text-align: center; 
    font-size: 2vh;   
  }
th {
    border-right: 1px solid white;
    /*background: rgb(76,106,145);
    background: linear-gradient(180deg, rgba(76,106,145,1) 0%, rgba(60,84,116,1) 100%);
    */
    background-image: linear-gradient(180deg, var(--main-color) 0%, var(--main-color-dark) 100%);
    color: white;
    padding: .75vh;
    font-size: 2vh;
    font-family: 'Quicksand'
}
.thNoBorder {
    border-right: .25px solid gray !important;
}

.TableButton {
    width: 100%;
    height: 4vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.iconProps:hover {
    fill: white !important;
    stroke: yellow !important;
    stroke-width: 5px;
    -webkit-filter: drop-shadow( 0 0 8px rgb(255, 255, 255, 1));
    filter: drop-shadow( 0 0 8px rgb(255, 255, 255, 1));
}

@-webkit-keyframes ButtonHover {
    0%{background-position:51% 0%}
    50%{background-position:50% 100%}
    100%{background-position:51% 0%}
}
@-moz-keyframes ButtonHover {
    0%{background-position:51% 0%}
    50%{background-position:50% 100%}
    100%{background-position:51% 0%}
}
@keyframes ButtonHover {
    0%{background-position:51% 0%}
    50%{background-position:50% 100%}
    100%{background-position:51% 0%}
}

